import React from "react"
import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  Typography,
  Container,
} from "@material-ui/core"

const OurServices = () => {
  const services = [
    {
      title: "Alchtec Suite",
      description:
        "Nuestra plataforma de IA generativa en donde podrás utilizar nuestro ecosistema de soluciones tecnológicas.",
    },
    {
      title: "Asesoría IA generativa",
      description:
        "Descubre oportunidades para maximizar la eficiencia y la innovación en tus procesos empresariales.",
    },
    {
      title: "Implementación de Software",
      description:
        "Incorpora la tecnología para revolucionar tu infraestructura tecnológica y obtener ventajas competitivas en tu sector.",
    },
    {
      title: "Charlas educativas",
      description:
        "Llevamos conocimientos prácticos directamente a tu equipo, potenciando la comprensión y aplicación efectiva de IA en tu empresa.",
    },
  ]

  return (
    <div id="servicios">
      <Container maxWidth="xl">
        <Typography
          variant="h3"
          style={{
            fontSize: 32,
            fontWeight: 800,
            textAlign: "center",
            marginBottom: "36px",
          }}
          align="center"
        >
          Nuestros Servicios
        </Typography>
        <Grid container spacing={3}>
          {services.map((service, index) => (
            <Grid item xs={12} sm={6} md={3} key={index}>
              <Card
                style={{
                  borderRadius: "24px",
                  boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
                  padding: 12,
                  minHeight: 220,
                }}
              >
                <CardContent>
                  <Typography
                    variant="h3"
                    component="h3"
                    style={{
                      fontSize: "22px",
                      fontFamily: "Gilroy Bold",
                      marginBottom: 12,
                    }}
                  >
                    {service.title}
                  </Typography>
                  <Typography
                    variant="body2"
                    style={{ fontSize: "18px", fontFamily: "Gilroy Medium" }}
                  >
                    {service.description}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </div>
  )
}

export default OurServices
