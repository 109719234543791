import React from "react"
import {
  Grid,
  Typography,
  Card,
  Container,
  Button,
  Hidden,
} from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import frame1 from "../assets/app-frame-1.webp"
import frame2 from "../assets/app-frame-2.webp"
import frame3 from "../assets/app-frame-3.webp"
import { callAnalyticsEvent } from "../utils"

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  card: {
    width: "100%",
    background: "inherit",
    padding: "24px",
  },
  icon: {
    color: theme.palette.secondary.main,
    marginBottom: 24,
  },
  title: {
    textAlign: "center",
    [theme.breakpoints.up("lg")]: {
      fontSize: 26,
    },
  },
  subtitle: {
    fontSize: 24,
    textAlign: "justify",
    [theme.breakpoints.up("lg")]: {
      fontSize: 20,
    },
  },
  media: {
    width: "60%",
    borderRadius: "100%",
    [theme.breakpoints.down("md")]: {
      height: "15vw",
      width: "15vw",
    },
  },
  cardTitle: {
    fontWeight: 800,
    marginTop: 12,
    fontSize: "20px",
  },
  cardDescription: {
    fontSize: "20px",
    fontFamily: "Gilroy Medium",
    [theme.breakpoints.down("md")]: {
      fontSize: "16px",
    },
  },
  cardButton: {
    fontWeight: 900,
    padding: "9px 20px 9px 20px",
    color: "#fff",
    borderRadius: "15px",
    width: "230px",
  },
  cardButtonCopy: {
    fontWeight: 500,
    textTransform: "uppercase",
    fontFamily: "Gilroy Bold",
  },
}))

const FeatureCard = ({ icon, title, description, setOpenChat }) => {
  const classes = useStyles()
  return (
    <Grid container justify="center" className={classes.card}>
      <img src={icon} alt={title} className={classes.media} />
    </Grid>
  )
}

const LandingPage = ({ setOpenChat }) => {
  const classes = useStyles()

  const features = [
    {
      icon: frame1,
      title: "Agente de Ventas",
      description:
        "Implementa un asistente de ventas 24/7 en tu sitio web o WhatsApp corporativo. Este asistente responde mensajes con información personalizada, agenda reuniones, muestra inventarios y proporciona detalles de servicios. Además, podrás ver el historial de interacciones y estadísticas clave en la Alchtec Suite.",
      disabled: false,
      buttonLabel: "Solicita una demo",
      action: () => {
        window.open(
          "https://calendly.com/alchemytechnologiessolutions/30min?back=1&month=2024-03&date=2024-03-29",
          "_blank"
        )
        callAnalyticsEvent("navigate.calendly")
      },
    },
    {
      icon: frame3,
      title: "EnterpriseGPT",
      description:
        "Una herramienta como ChatGPT, pero con el conocimiento específico de tu empresa. Realiza consultas sobre documentos, tareas y más en tus ERPs y CRMs. EnterpriseGPT optimiza el tiempo de tu equipo combinando la inteligencia artificial con el conocimiento de tu negocio.",
      disabled: false,
      buttonLabel: "Solicita una demo",
      action: () => {
        window.open(
          "https://calendly.com/alchemytechnologiessolutions/30min?back=1&month=2024-03&date=2024-03-29",
          "_blank"
        )
        callAnalyticsEvent("navigate.calendly")
      },
    },
    {
      icon: frame2,
      title: "Youtube AI Insights",
      description:
        "Extrae información clave de videos de YouTube y la compara con las necesidades de tu negocio. Esta herramienta identifica y presenta los datos más relevantes para ti, ayudándote a obtener insights específicos que pueden impulsar tus decisiones empresariales.",
      disabled: false,
      buttonLabel: "Solicita una demo",
      action: () => {
        window.open(
          "https://calendly.com/alchemytechnologiessolutions/30min?back=1&month=2024-03&date=2024-03-29",
          "_blank"
        )
        callAnalyticsEvent("navigate.calendly")
      },
    },
  ]

  return (
    <Container maxWidth="xl" id="soluciones">
      <Grid container className={classes.root}>
        <Grid container justify="center">
          <Typography
            variant="h3"
            style={{
              fontSize: 32,
              fontWeight: 800,
              textAlign: "center",
              marginBottom: "24px",
            }}
          >
            Conoce nuestras soluciones con IA generativa
          </Typography>
        </Grid>
        <Grid container spacing={3} justify="center">
          {features.map((feature, index) => {
            return (
              <Grid item xs={12} md={9} key={index} container>
                <Card
                  style={{
                    borderRadius: "24px",
                    boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
                    paddingBottom: 12,
                  }}
                >
                  <Grid container>
                    <Grid
                      item
                      xs={4}
                      md={2}
                      container
                      justify="center"
                      alignItems="center"
                    >
                      <FeatureCard
                        icon={feature.icon}
                        title={feature.title}
                        description={feature.description}
                      />
                    </Grid>

                    <Grid
                      item
                      xs={7}
                      style={{
                        paddingTop: 12,
                        paddingRight: 12,
                      }}
                    >
                      <Typography
                        variant="h3"
                        component="h3"
                        className={classes.cardTitle}
                      >
                        {feature.title}
                      </Typography>
                      <Typography className={classes.cardDescription}>
                        {feature.description}
                      </Typography>
                      <Hidden mdUp>
                        <Grid item xs={12} md={4} container alignItems="center">
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={feature.action}
                            className={classes.cardButton}
                            disabled={feature.disabled}
                            style={{ marginTop: 12, marginBottom: 12 }}
                          >
                            <Typography className={classes.cardButtonCopy}>
                              {feature.disabled
                                ? "Próximamente"
                                : feature.buttonLabel
                                  ? feature.buttonLabel
                                  : "¡PRUÉBALO!"}
                            </Typography>
                          </Button>
                        </Grid>
                      </Hidden>
                    </Grid>
                    <Hidden mdDown>
                      <Grid
                        item
                        xs={12}
                        md={3}
                        container
                        justify="center"
                        alignItems="center"
                      >
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={feature.action}
                          className={classes.cardButton}
                          disabled={feature.disabled}
                        >
                          <Typography className={classes.cardButtonCopy}>
                            {feature.disabled
                              ? "Próximamente"
                              : feature.buttonLabel
                                ? feature.buttonLabel
                              : "¡PRUÉBALO!"}
                          </Typography>
                        </Button>
                      </Grid>
                    </Hidden>
                  </Grid>
                </Card>
              </Grid>
            )
          })}
        </Grid>
      </Grid>
    </Container>
  )
}

export default LandingPage
