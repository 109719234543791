import React, { useEffect, useState } from "react"
import {
  Grid,
  Typography,
  Hidden,
  makeStyles,
  useTheme,
  Container,
  useMediaQuery,
  Button,
} from "@material-ui/core"
import heroImage from "../assets/hero.webp" // Adjust the import path as necessary
import { CheckCircle } from "@material-ui/icons"
import { callAnalyticsEvent } from "../utils"
import { useSpring, animated } from "@react-spring/web"

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "30vh",
    backgroundSize: "cover", // Cover the entire container
    backgroundPosition: "center", // Center the background image
    [theme.breakpoints.down("md")]: {
      minHeight: "20vh",
    },
    [theme.breakpoints.up("md")]: {
      marginTop: "150px",
    },
    [theme.breakpoints.up("lg")]: {
      height: "55vh",
    },
  },
  title: {
    marginBottom: 12,
    textAlign: "center",
    fontWeight: 400,
    fontSize: "44px",
    [theme.breakpoints.down("md")]: {
      fontSize: 26,
      marginTop: 24,
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "3.6em",
      textAlign: "left",
      marginBottom: "0.5em",
    },
  },
  punchline: {
    fontWeight: 500,
    fontSize: "24px",
    marginBottom: 12,
    lineHeight: 1.2,
    textAlign: "center",
    fontFamily: "Gilroy Medium",
    [theme.breakpoints.down("md")]: {
      fontSize: 20,
    },
    [theme.breakpoints.up("md")]: {
      textAlign: "left",
      marginBottom: "1em",
      fontSize: "1.5em",
      width: "80%",
    },
    [theme.breakpoints.up("xl")]: {
      textAlign: "left",
      marginBottom: "1em",
      fontSize: "2.1em",
    },
  },

  hero: {
    zIndex: 101,
    alignSelf: "center",
    objectFit: "cover",
    width: "100%",
    height: "100%",
    [theme.breakpoints.down("md")]: {
      width: "100vw",
    },
    [theme.breakpoints.up("lg")]: {
      height: "60vh",
    },
  },
  imageContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    overflow: "hidden",
    position: "relative",
    backgroundImage: `url(${heroImage})`,
    backgroundSize: "cover", // Cover the entire container
    backgroundPosition: "center", // Center the background image
    [theme.breakpoints.down("md")]: {
      minHeight: "20vh",
    },
    [theme.breakpoints.up("lg")]: {
      width: "80%",
      margin: "72px auto",
      height: "40vh",
    },
  },
  buttonLabel: {
    fontWeight: 500,
    textTransform: "uppercase",
    fontFamily: "Gilroy Bold",
  },
  textContainer: {
    padding: 12,
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("md")]: {
      marginTop: "12px",
    },
    [theme.breakpoints.up("md")]: {
      height: "70vh",
    },
    [theme.breakpoints.up("xl")]: {
      padding: 0,
    },
  },
  button: {
    fontWeight: 900,
    padding: "9px 24px 9px 24px",
    color: "#fff",
    borderRadius: "15px",
  },
  item: {
    fontSize: "20px",
    [theme.breakpoints.down("md")]: {
      fontSize: 16,
    },
  },
}))

const Hero = () => {
  const classes = useStyles()
  const theme = useTheme()
  const [animationsActive, setAnimationsActive] = useState(true)

  const titleSpring = useSpring({})

  const punchlineSpring = useSpring({})

  const buttonSpring = useSpring({})

  useEffect(() => {
    setAnimationsActive(false)
  }, []) // El array vacío asegura que esto solo se ejecute una vez

  return (
    <Container maxWidth="xl" className={classes.root}>
      <Grid container justify="center">
        <Hidden mdUp>
          <Grid
            item
            xs={12}
            style={{ position: "relative", marginTop: "64px" }}
          >
            <Grid container className={classes.imageContainer}></Grid>
          </Grid>
        </Hidden>
        <Grid item xs={12} md={6} className={classes.textContainer}>
          <animated.div style={titleSpring}>
            <Typography variant="h1" component="h1" className={classes.title}>
              ¿Quieres saber como implementar IA generativa en tu empresa?
            </Typography>
          </animated.div>
          <animated.div style={punchlineSpring}>
            <Typography
              variant="h2"
              component="h2"
              className={classes.punchline}
            >
              Descubre cómo la IA puede optimizar procesos y catapultar la
              innovación en tu negocio.
            </Typography>
          </animated.div>
          <Grid
            container
            justify={
              useMediaQuery(theme.breakpoints.up("md")) ? null : "center"
            }
          >
            <Button
              className={classes.button}
              color="primary"
              variant="contained"
              onClick={() => {
                window.open(
                  "https://calendly.com/alchemytechnologiessolutions/30min?back=1&month=2024-03&date=2024-03-29",
                  "_blank"
                )
                callAnalyticsEvent("navigate.calendly")
              }}
            >
              Agenda una reunión
            </Button>
          </Grid>
          <Typography
            style={{ marginTop: 24, marginBottom: 24 }}
            className={classes.item}
          >
            Con Alchtec podrás:
          </Typography>
          <Grid item container style={{ marginBottom: "12px" }}>
            <CheckCircle color="secondary" style={{ marginRight: "6px" }} />
            <Typography style={{ marginTop: "1px" }} className={classes.item}>
              Descubrir oportunidades y riesgos
            </Typography>
          </Grid>
          <Grid item container style={{ marginBottom: "12px" }}>
            <CheckCircle color="secondary" style={{ marginRight: "6px" }} />
            <Typography className={classes.item} style={{ marginTop: "1px" }}>
              Implementar IA a medida
            </Typography>
          </Grid>
          <Grid item container style={{ marginBottom: "12px" }}>
            <CheckCircle color="secondary" style={{ marginRight: "6px" }} />
            <Typography className={classes.item} style={{ marginTop: "1px" }}>
              Educar a tu organización respecto a IA
            </Typography>
          </Grid>
          <Grid item container style={{ marginBottom: "12px" }}>
            <CheckCircle color="secondary" style={{ marginRight: "6px" }} />
            <Typography className={classes.item} style={{ marginTop: "1px" }}>
              Transformar tu organización
            </Typography>
          </Grid>
        </Grid>
        <Grid item md={6} style={{ position: "relative" }}>
          <Hidden smDown>
            <Grid container className={classes.imageContainer}></Grid>
          </Hidden>
        </Grid>
      </Grid>
    </Container>
  )
}

export default Hero
