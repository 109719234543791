import React, { useEffect, useRef, useState } from "react"
import Hero from "../components/Hero"
import Features from "../components/Features"
import "./styles.css"
import { NavbarLayout } from "../layouts"
import OurServices from "../components/OurServices"

const WrappedApp = () => {
  const [openChat, setOpenChat] = useState(false)

  return (
    <NavbarLayout setOpenChat={setOpenChat} openChat={openChat}>
      <App setOpenChat={setOpenChat} />
    </NavbarLayout>
  )
}
const App = ({ setOpenChat }) => {
  const pricingRef = useRef()

  useEffect(() => {
    const doNotShow = localStorage.getItem("doNotShowAgainToggle")
    if (!doNotShow) {
    }
  }, [])

  useEffect(() => {
    if (window.location.hash === "#price") {
      pricingRef.current.scrollIntoView({ behavior: "smooth" })
    }
  }, [])

  return (
    <div>
      <Hero />
      <div style={{ height: 120 }} />
      <OurServices />
      <div style={{ height: 120 }} />
      <Features setOpenChat={setOpenChat} />
    </div>
  )
}

export default WrappedApp
